<template>
    <div>
        <div class="user">
            <p class="title">个人中心</p>

            <div class="personal">
                <div class="picture">
                    <img :src="getProfile" />
                </div>

                <div class="edit" @click="router.push('/EditUser')">
                    <span class="iconfont icon-a-iconpay_huaban1fuben25" style="font-size: 26px; color:#BDBDBD"></span>
                </div>

                <div class="name">
                    <p>{{ userInfo.NICK_NAME }}</p>
                    <p>{{ userInfo.USER_NAME }}</p>
                </div>

                <p class="wallet" @click="clipboardText('.wallet')" :data-clipboard-text="userInfo.KPAY_ADDR">
                    <span class="address">钱包地址：{{ userInfo.KPAY_ADDR }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben23" style="font-size: 24px; color:#3366EE; vertical-align: middle;"></span>
                </p>
            </div>

            <ul class="features">
                <li @click="router.push('/ChangePassword')">
                    <span class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben67"></span>
                    <span class="tit">设置密码</span>
                    <a class="islink"></a>
                </li>
                <li @click="isShow = true">
                    <span class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben69"></span>
                    <span class="tit">用户须知</span>
                    <a class="islink"></a>
                </li>
                <li v-if="userInfo.USER_TYPE === '1'" @click="querySellInfo('SellConfig')">
                    <span class="iconfont icon-a-payicon75_huaban1fuben74"></span>
                    <span class="tit">商户配置</span>
                    <a class="islink"></a>
                </li>
                <li v-if="userInfo.USER_TYPE === '1'" @click="querySellInfo('PassTypeSetting')">
                    <span class="iconfont icon-a-payicon75_huaban1fuben74"></span>
                    <span class="tit">设置授权码</span>
                    <a class="islink"></a>
                </li>
                <li @click="clearCache">
                    <span class="iconfont icon-a-payicon_clear"></span>
                    <span class="tit">清理缓存</span>
                </li>
                <li v-if="isApp">
                    <span class="iconfont icon-a-payicon_number"></span>
                    <span class="tit">版本号</span>
                    <a>v 1.0.0</a>
                </li>
                <li @click="logout">
                    <span class="iconfont icon-a-payicon_exit_huaban1fuben70"></span>
                    <span class="tit">退出登录</span>
                </li>
            </ul>

            <van-action-sheet v-model:show="isShow" title="用户须知" :close-on-click-overlay="false">
                <div class="content">
                    <p>
                        k宝钱包是基于C2C原理开发的应用平台，交易是会员与 会员之间的大厅互买模式。敬请大家一定要注意，并非 充值模式。流程示范：注册→购买K币→到商户平台充 值→提款下分到K宝钱包→到我要卖币挂单等待买家购买 →买家付款→卖家确认→完成交易！在交易过程当中， 还有一些注意事项，请您务必阅读。
                    </p>

                    <p>
                        第一：为了保证会员之间的交易安全，平台采取的是实 名制用户认证，并终身无法修改认证信息！会员必须遵 守规定，严格启用本人身份信息，银行账户信息,等；买家付款时必须使用本人身份信息的银行卡或者其他支付工具来购买，如果使用他人银行账户代为付款，卖家有权可以不予确认收款。由于本人录入信息不符而造成的一切损失，由会员自行承担，平台不担负任何责任；
                    </p>

                    <p>
                        第二：为了保证卖家的信息安全，请买家务必在有需求的时候再进行订单确认，如若发生恶意刷单，锁定卖家额度而不付款的用户，平台将会自动冻结账户；
                    </p>

                    <p>
                        第三：在购买以及出售K币的交易时间，平台规定是必须在5分钟在内完成，会员在购买或者出售的时候，一定要非常关注交易是否正在进行当中，如有异议请及时联系平台客服，申请人工介入！由于买卖双方长时间不确定的交易，系统将自动判定为成交或者取消，由于系统判定是无法做到百分百准确，平台不承担任何责任。再次重申，请双方在交易的过程当中要时刻注意订单变化！
                    </p>

                    <p>
                        第四：『买家』在购买K币的时候，此订单一旦付款成功，请立即点击『我已转账』确认，确认以后5分钟内如果还没有收到币，此订单便为争议订单，请立即联系在线客服，申请人工介入！
                    </p>

                    <p>
                        第五：会员在出售K币的时候，此订单一旦挂单成功，如有『买家』下单购买并点击了『我已转账』确认，请您马上查询是否收到汇款，如没有收到『买家』的转账，请立即点击『暂停打币』功能，并联系在线客服，申请人工介入！
                    </p>

                    <p>第六：买卖双方在卖币买币过程当中，如果恶意暂打币，故意为难买家，平台将会冻结账户。</p>

                    <div class="btn">
                        <van-button block type="primary" round size="normal" @click="isShow = false">确 定</van-button>
                    </div>
                </div>
            </van-action-sheet>
        </div>

        <!-- 谷歌验证 -->
        <van-popup v-model:show="validationVisible" lock-scroll :close-on-click-overlay="false" round>
            <div class="popup">
                <div style="text-align:center; font-weight:blod; font-size:18px; line-height: 24px; padding: 10px; border-bottom: #eee 1px solid">授权验证</div>
                <van-form @submit="onSubmit">
                    <van-field v-model="sellVerify" type="password" name="sellVerify" label="授权秘钥" placeholder="秘钥" :rules="[{ required: true, message: '秘钥不能为空' }]" />
                    <div class="btns">
                        <van-button block size="small" @click="validationCancel">取消</van-button>
                        <van-button block type="primary" size="small" native-type="submit">确认</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>

        <!-- 支付密码 -->
        <van-popup v-model:show="secretkeyValidationVisible" lock-scroll :close-on-click-overlay="false" round>
            <div class="popup">
                <div style="text-align:center; font-weight:blod; font-size:18px; line-height: 24px; padding: 10px; border-bottom: #eee 1px solid">支付密码</div>
                <van-form @submit="onSubmit">
                    <van-field v-model="sellVerify" type="password" name="secretKey" label="支付密码" placeholder="密码" :rules="[{ required: true, message: '密码不能为空' }]" />
                    <div class="btns">
                        <van-button block size="small" @click="validationCancel">取消</van-button>
                        <van-button block type="primary" size="small" native-type="submit">确认</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { userLogout, getSellVerify } from '@/api'
import { Dialog, Toast } from 'vant'
import { GOPAY_USERINFO, GOPAY_FILECONFIG, GOPAY_KTOKEN, clipboardText } from '@/assets/js/common.js'
import md5 from 'js-md5'

export default {
    setup() {
        const state = reactive({
            isApp: true,
            userInfo: {
                ACC_BAL: 0,
                ACC_BAL_SALE: 0,
                ID: 0,
                ISSET_DRAWPASS: 0,
                ISSET_REALNAME: 0,
                KPAY_ADDR: '',
                NICK_NAME: '',
                SALE_BAL: 0,
                TRADE_BAL: 0,
                USER_NAME: '',
                USER_PIC: '',
                USER_TYPE: ''
            },
            fileConfig: '',
            isShow: false,
            validationVisible: false, // 谷歌验证
            sellVerify: '',
            secretkeyValidationVisible: false, // 密钥验证
            secretKey: '',
            testVisible: true
        })

        const router = useRouter()
        const store = useStore()
        let verifyToPath = ''

        state.userInfo = store.state.userInfo
        state.fileConfig = store.state.fileConfig

        const onSubmit = params => {
            let pass = params.sellVerify || md5(params.secretKey)
            getSellVerify({ pass }).then(res => {
                state.sellVerify = state.secretKey = ''
                state.validationVisible = state.secretkeyValidationVisible = false
                if (res.code === 200) {
                    router.push({ name: verifyToPath, params: res.data })
                } else {
                    Toast.fail(res.message)
                }
            })
        }
        const validationCancel = () => {
            state.sellVerify = state.secretKey = ''
            state.secretkeyValidationVisible = state.validationVisible = false
        }

        const clearCache = () => {
            // todo:
            // localStorage.removeItem(GOPAY_USERINFO)
            // localStorage.removeItem(GOPAY_FILECONFIG)
            // localStorage.removeItem(GOPAY_KTOKEN)
            Toast.loading({
                message: '清除中...',
                forbidClick: true
            })
            setTimeout(() => {
                Toast.success('缓存已清除！')
            }, 2000)
        }

        const querySellInfo = toPath => {
            verifyToPath = toPath
            if (state.userInfo.SELL_PASSTYPE === 0) {
                state.secretkeyValidationVisible = true
            }
            if (state.userInfo.SELL_PASSTYPE === 1) {
                state.validationVisible = true
            }
        }

        const logout = () =>
            Dialog.confirm({
                title: '温馨提示',
                message: '确认退出登录'
            })
                .then(() =>
                    userLogout().then(res => {
                        if (res.status) {
                            localStorage.removeItem(GOPAY_USERINFO)
                            localStorage.removeItem(GOPAY_FILECONFIG)
                            localStorage.removeItem(GOPAY_KTOKEN)
                            window.connection = null
                            Toast.success('登出成功！')
                            router.push('/Login')
                        }
                    })
                )
                .catch(() => {})

        return {
            ...toRefs(state),
            router,
            clipboardText,
            onSubmit,
            validationCancel,
            clearCache,
            querySellInfo,
            logout
        }
    },
    computed: {
        getProfile() {
            const profile = this.userInfo.USER_PIC || 'default.png'
            return require(`@/assets/profiles/${profile}`)
        }
    },
    created() {
        if (typeof QRScanner !== 'object') {
            this.isApp = false
        } else {
            this.isApp = true
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    width: 90vw;
    .btns {
        margin: 16px;
        text-align: center;
        button {
            display: inline-block;
            width: 100px;
            margin: 0 20px;
        }
    }
}
.user {
    overflow: hidden;
    height: calc(100vh - 50px);
    background: url('~@/assets/img/topic_bg.png') center -44px/100% 250px no-repeat, linear-gradient(to bottom, #3366ee 0, #3366ee 165px, #ffffff 165px, #ffffff 300px);
    box-sizing: border-box;
    padding: 0 w(15);

    .title {
        color: #ffffff;
        font-size: 17px;
        line-height: 22px;
        margin-top: h(12);
        text-align: center;
    }

    .personal {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        position: relative;
        margin-top: h(64);
        height: h(158);
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(109, 136, 251, 0.2);
        border-radius: 15px;
        text-align: center;

        .picture {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: h(90);
            border-radius: 50%;
            overflow: hidden;

            img {
                width: h(90);
                height: h(90);
                border-radius: 50%;
            }
        }

        .edit {
            position: absolute;
            top: h(23);
            right: w(32);

            .edit-text {
                margin-left: w(5);
                vertical-align: top;
            }
        }

        .name {
            margin-top: h(55);

            & p {
                color: #000000;
                font-size: 20px;
                line-height: 109.48%;
            }

            & p:last-child {
                margin-top: h(7);
                color: #4f4f4f;
                font-size: 12px;
            }
        }
        .wallet {
            margin-top: h(12);

            .address {
                color: #4f4f4f;
                font-size: 12px;
                line-height: 16px;
                margin-right: w(16);
                vertical-align: text-bottom;
            }

            i {
                font-size: 16px;
            }
        }
    }

    .features {
        margin-top: h(33);
        border-top: #eee 0px solid;
        font-size: 16px;
        li {
            border-bottom: #eee 0px solid;
            span,
            strong,
            a,
            a:after {
                line-height: 48px;
                display: inline-block;
                vertical-align: top;
            }
            .iconfont {
                color: #3366ee;
                font-size: 24px;
            }
            .tit {
                font-size: 16px;
                padding-left: 10px;
            }
            a {
                float: right;
                color: #bdbdbd;
                padding-right: 24px;
            }
            .islink {
                padding-right: 0;
                &:after {
                    content: '\e623';
                    color: #bdbdbd;
                    font-family: 'iconfont' !important;
                    font-size: 16px;
                    font-style: normal;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 24px;
                }
            }
        }
    }

    .content {
        padding: 16px 16px h(30);
        text-align: left;

        p {
            margin-bottom: h(15);
            font-size: 12px;
            line-height: 16px;
            color: #000000;
        }

        .btn {
            margin-top: h(30);
        }
    }
}
</style>
